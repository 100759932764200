import { capitalizeFirstLetter } from '../../CancelBooking/CancelSummary';
import { calculateTotalExcludingCtMarkup } from '../PaymentSummary';

export const SMBCount = (flightType: { segments: string | any[] }) => {
  let seatCount = 0;
  let mealCount = 0;
  let baggageCount = 0;
  for (let i = 0; i < flightType?.segments?.length; i++) {
    for (let j = 0; j < flightType?.segments?.[i]?.bookingInfos?.length; j++) {
      flightType?.segments[i]?.bookingInfos?.[i]?.selectedAncillaries?.find((seat: any) => {
        seatCount = seat?.type?.toLowerCase() == 'seat' ? seatCount + 1 : seatCount;
      });
      flightType?.segments[i]?.bookingInfos?.[i]?.selectedAncillaries?.find((meal: any) => {
        mealCount = meal?.type?.toLowerCase() == 'meal' ? mealCount + 1 : mealCount;
      });
      flightType?.segments[i]?.bookingInfos?.[i]?.selectedAncillaries?.find((baggage: any) => {
        baggageCount = baggage?.type?.toLowerCase() == 'baggage' ? baggageCount + 1 : baggageCount;
      });
    }
  }
  return { seatCount, mealCount, baggageCount };
};

export const getTableData = (props: any, onward: any) => {
  const orgCode = props?.data?.userOrganization?.organizationCode;
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );

  const orderAmount = props?.data?.orderAmount ?? 0;
  const currency = orderAmount?.currency ?? '';
  const totalAmount = orderAmount?.totalAmount ?? 0;
  const baseAmount = parseFloat(
    orderAmount?.flightBasedBreakUp?.[0]?.baseAmount ?? orderAmount?.baseAmount ?? 0
  );
  const markup = parseFloat(
    orderAmount?.flightBasedBreakUp?.[0]?.markup ?? orderAmount?.markup ?? 0
  );
  const taxAmount = parseFloat(orderAmount?.taxAmount ?? 0);
  const convenienceFee = orderAmount?.convenienceFee ?? 0;
  const amountInCash = orderAmount?.paymentSummary?.amountInCash ?? 0;
  const amountInCoins = orderAmount?.paymentSummary?.amountInCoins ?? 0;
  const paymentMode = orderAmount?.paymentSummary?.paymentMode ?? 'UPI';
  let niyoPremium = orderAmount?.addOnServicesAmount?.addonServiceBreakUps?.find((obj: { type: string; })=> obj?.type === 'NIYO_GOLD_PREMIUM_PLAN')
  return amountInCoins > 0
    ? [
        {
          id: 0,
          label: `${onwardDeparture?.departure?.airport?.city} -> ${onwardArrival?.arrival?.airport?.city}`,
          value: `${currency} ${baseAmount + taxAmount + markup}`,
          header: true
        },
        {
          id: 1,
          label: 'Base fare',
          value: `${currency} ${baseAmount}`
        },
        {
          id: 2,
          label: 'Taxes & fees',
          value: `${currency} ${taxAmount}`
        },
        {
          id: 3,
          label: 'Markup',
          value: `${currency} ${markup}`
        },
        {
          id: 4,
          label: 'Total airfare',
          value: `${currency} ${totalAmount - (convenienceFee ?? 0)}`
        },
        {
          id: 5,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `${amountInCoins < 0 ? '-' : ''} ${currency} ${amountInCoins ?? 0}`,
          success: true
        },
        {
          id: 6,
          label: 'Convenience Fee',
          value: `${currency} ${convenienceFee ?? 0}`
        },
        props?.modalOpen && {
          id: 7,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `${amountInCoins < 0 ? '-' : ''} ${currency} ${amountInCoins ?? 0}`,
          success: true
        },
        niyoPremium && {
          id: 8,
          label: `Premium Gold plan`,
          value: `${currency} ${niyoPremium?.totalAmount ?? 0}`
        },
        {
          id: 9,
          label: `Total (amount paid via ${
            paymentMode ? paymentMode?.replace(/_/g, ' ')?.toLowerCase() : 'UPI'
          })`,
          value: `${currency} ${amountInCash ?? 0}`
        }
      ].filter(Boolean)
    : [
        {
          id: 0,
          label: `${onwardDeparture?.departure?.airport?.city} -> ${onwardArrival?.arrival?.airport?.city}`,
          value: `${currency} ${baseAmount + taxAmount + markup}`,
          header: true
        },
        {
          id: 1,
          label: 'Base fare',
          value: `${currency} ${baseAmount ?? 0}`
        },
        {
          id: 2,
          label: 'Taxes & fees',
          value: `${currency} ${taxAmount}`
        },
        {
          id: 3,
          label: 'Markup',
          value: `${currency} ${markup}`
        },
        {
          id: 4,
          label: 'Total airfare',
          value: `${currency} ${totalAmount - (convenienceFee ?? 0)}`
        },
        {
          id: 5,
          label: 'Convenience Fee',
          value: `${currency} ${convenienceFee ?? 0}`
        },
        props?.modalOpen && {
          id: 6,
          label: `${
            orgCode
              ? ` ${capitalizeFirstLetter(
                  props?.data?.userOrganization?.['organizationCode']
                )} discount`
              : 'Niyo global coins discount'
          }`,
          value: `${amountInCoins < 0 ? '-' : ''} ${currency} ${amountInCoins ?? 0}`,
          success: true
        },
        niyoPremium && {
          id: 7,
          label: `Premium Gold plan`,
          value: `${currency} ${niyoPremium?.totalAmount ?? 0}`
        },
        {
          id: 8,
          label: `Total (amount paid via ${
            paymentMode ? paymentMode?.replace(/_/g, ' ')?.toLowerCase() : 'UPI'
          })`,
          value: `${currency} ${amountInCash ?? 0}`
        }
      ].filter(Boolean);
};

export const getRoundTripTable = (
  props: any,
  onward: any,
  onwardBreakup: any,
  returnData: any,
  returnBreakup: any
) => {
  const orgCode = props?.data?.userOrganization?.organizationCode;
  let onwardDeparture = onward?.segments?.find((segment: any) => segment?.segmentId === 1);
  let onwardArrival = onward?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length
  );
  let returnArrival = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + 1
  );
  let returnDeparture = returnData?.segments?.find(
    (segment: any) => segment?.segmentId === onward?.segments?.length + returnData?.segments?.length
  );

  const orderAmount = props?.data?.orderAmount;
  const currency = orderAmount?.currency;
  const totalAmount = orderAmount?.totalAmount ?? 0;
  const baseAmount = orderAmount?.flightBasedBreakUp?.[0]?.baseAmount ?? orderAmount?.baseAmount;
  const markup = orderAmount?.flightBasedBreakUp?.[0]?.markup ?? orderAmount?.markup ?? 0;
  const amountInCash = orderAmount?.paymentSummary?.amountInCash ?? 0;
  const amountInCoins = orderAmount?.paymentSummary?.amountInCoins ?? 0;
  const paymentMode = orderAmount?.paymentSummary?.paymentMode;
  const taxAmount = orderAmount?.taxAmount ?? 0;
  const convenienceFee = orderAmount?.convenienceFee ?? 0;
  let niyoPremium = orderAmount?.addOnServicesAmount?.addonServiceBreakUps?.find((obj: { type: string; })=> obj?.type === 'NIYO_GOLD_PREMIUM_PLAN')
  return props?.data?.orderAmount?.flightBasedBreakUp?.length > 0
    ? [
        {
          id: 0,
          label: `${onwardDeparture?.departure?.airport?.city} -> ${onwardArrival?.arrival?.airport?.city}`,
          value: `${currency} ${onwardBreakup?.totalAmount ?? 0}`,
          header: true
        },
        {
          id: 1,
          label: 'Base fare',
          value: `${currency} ${onwardBreakup?.baseAmount ?? 0}`
        },
        {
          id: 2,
          label: 'Taxes & fees',
          value: onwardBreakup?.taxesAndFees
            ? `${currency} ${calculateTotalExcludingCtMarkup(onwardBreakup?.taxesAndFees)}`
            : 0
        },
        {
          id: 3,
          label: 'Markup',
          value: `${currency} ${onwardBreakup?.markup ?? 0}`
        },
        {
          id: 4,
          label: `${returnArrival?.departure?.airport?.city} -> ${returnDeparture?.arrival?.airport?.city}`,
          value: `${currency} ${returnBreakup?.totalAmount ?? 0}`,
          header: true,
          secondHeader: true
        },
        {
          id: 5,
          label: 'Base fare',
          value: `${currency} ${returnBreakup?.baseAmount ?? 0}`,
          header: false
        },

        {
          id: 6,
          label: `Taxes & fees`,
          value: returnBreakup?.taxesAndFees
            ? `${currency} ${calculateTotalExcludingCtMarkup(returnBreakup?.taxesAndFees)}`
            : 0
        },
        {
          id: 7,
          label: 'Markup',
          value: `${currency} ${returnBreakup?.markup ?? 0}`
        },
        {
          id: 7.1,
          label: 'Total airfare',
          value: `${currency} ${totalAmount - convenienceFee}`
        },
        {
          id: 8,
          label: 'Convenience Fee',
          value: `${currency} ${convenienceFee ?? 0}`
        },
        amountInCoins > 0 && {
          id: 9,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `${amountInCoins > 0 ? '-' : ''} ${currency} ${amountInCoins ?? 0}`,
          success: true
        },
        props?.modalOpen && {
          id: 10,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `- ${currency} ${amountInCoins ?? 0}`,
          success: true
        },
        niyoPremium && {
          id: 11,
          label: `Premium Gold plan`,
          value: `${currency} ${niyoPremium?.totalAmount ?? 0}`
        },
        {
          id: 12,
          label: `Total (amount paid via ${
            paymentMode ? paymentMode?.replace(/_/g, ' ')?.toLowerCase() : 'UPI'
          })`,
          value: `${currency} ${amountInCash ?? 0}`
        }
      ].filter(Boolean)
    : [
        {
          id: 0,
          label: ` ${onwardDeparture?.departure?.airport?.city} | ${
            returnDeparture?.departure?.airport?.iata ?? ''
          }`,
          value: `${currency} ${totalAmount ?? 0}`,
          header: true
        },
        {
          id: 1,
          label: 'Base fare',
          value: `${currency} ${baseAmount ?? 0}`
        },
        {
          id: 2,
          label: 'Taxes & fees',
          value: `${currency} ${taxAmount}`
        },
        {
          id: 3,
          label: 'Markup',
          value: `${currency} ${markup}`
        },
        amountInCoins > 0 && {
          id: 4,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `${amountInCoins > 0 ? '-' : ''} ${currency} ${amountInCoins}`,
          success: true
        },
        {
          id: 5,
          label: 'Convenience Fee',
          value: `${currency} ${convenienceFee}`
        },
        props?.modalOpen && {
          id: 6,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `- ${currency} ${amountInCoins}`,
          success: true
        },
        niyoPremium && {
          id: 6.1,
          label: `Premium Gold plan`,
          value: `${currency} ${niyoPremium?.totalAmount ?? 0}`
        },
        {
          id: 6.2,
          label: `Total (amount paid via ${
            paymentMode ? paymentMode?.replace(/_/g, ' ')?.toLowerCase() : 'UPI'
          })`,
          value: `${currency} ${amountInCash}`
        }
      ].filter(Boolean);
};

export const getRefundTable = (props: any) => {
  const orgCode = props?.data?.userOrganization?.organizationCode;
  const orderAmount = props?.data?.orderAmount;
  const currency = orderAmount?.currency;
  const totalAmount = orderAmount?.totalAmount ?? 0;
  const amountInCash = orderAmount?.paymentSummary?.amountInCash ?? 0;
  const amountInCoins = orderAmount?.paymentSummary?.amountInCoins ?? 0;
  const paymentMode = orderAmount?.paymentSummary?.paymentMode;
  const additionalInfo = orderAmount?.paymentSummary?.refundSummary?.additionalInfo;
  const refundCharges = additionalInfo?.totalRefundCharges ?? 0;
  const airlineCharges = additionalInfo?.airLineCharges ?? 0;
  const refundSummary = props?.data?.orderAmount?.paymentSummary?.refundSummary;
  const seatCharges = orderAmount?.seatCharges ?? 0;
  const mealCharges = orderAmount?.mealCharges ?? 0;
  const baggageCharges = orderAmount?.baggageCharges ?? 0;
  const nonRefundableFeeCharges = additionalInfo?.totalNonRefundableFeeCharges;
  const convenienceFeeCharges = additionalInfo?.convinceFeeCharges ?? 0;
  const totalRefundAmount = refundSummary?.totalRefundAmount ?? 0;
  const refundAmountInCash = refundSummary?.refundAmountInCash ?? 0;
  const totalNonRefundableFeeCharges = refundSummary?.totalRefundAmount ?? 0;

  return amountInCoins > 0
    ? [
        {
          id: 1,
          label: 'A. Amount paid',
          value: `${currency} ${totalAmount ?? 0}`,
          header: true
        },
        {
          id: 2,
          label: `Paid by customer (via ${
            paymentMode ? paymentMode?.replace(/_/g, ' ')?.toLowerCase() : 'UPI'
          })`,
          value: `${currency} ${amountInCash ?? 0}`,
          header: false
        },
        {
          id: 3,
          label: `${
            !orgCode || orgCode?.toLowerCase()?.includes('niyo')
              ? 'Niyo global coins discount'
              : `${capitalizeFirstLetter(orgCode)} discount`
          }`,
          value: `${currency} ${amountInCoins ?? 0}`,
          header: false,
          success: true
        },
        {
          id: 4,
          label: 'B. Cancellation charges',
          value: `- ${currency} ${parseFloat(refundCharges)}`,
          header: true
        },
        {
          id: 5,
          label: 'Airline fare',
          value: `${currency} ${airlineCharges}`,
          header: false
        },
        {
          id: 6,
          label: 'Partner charges',
          value: `${currency} ${additionalInfo?.partnerCharges ?? 0}`,
          header: false
        },
        {
          id: 7,
          label: 'C. Non refundable',
          value: `- ${currency} ${nonRefundableFeeCharges}`,
          header: true
        },
        {
          id: 8,
          label: 'Convenience fee',
          value: `${currency} ${convenienceFeeCharges}`,
          header: false
        },
        {
          id: 9,
          label: 'Seat Charges',
          value: `${currency} ${seatCharges}`,
          header: false
        },
        {
          id: 10,
          label: 'Meal Charges',
          value: `${currency} ${mealCharges}`,
          header: false
        },
        {
          id: 11,
          label: 'Baggage Charges',
          value: `${currency} ${baggageCharges}`,
          header: false
        },
        {
          id: 12,
          label: 'Total refund amount',
          value: `${currency} ${totalRefundAmount}`
        },
        {
          id: 13,
          label: 'Refunded to Bank A/C',
          value: `${currency} ${refundAmountInCash}`
        }
      ]
    : [
        {
          id: 1,
          label: 'A. Amount paid',
          value: `${currency} ${totalAmount ?? 0}`,
          header: true
        },
        {
          id: 2,
          label: `Paid by customer (via ${
            paymentMode ? paymentMode?.replace(/_/g, ' ')?.toLowerCase() : 'UPI'
          })`,
          value: `${currency} ${amountInCash}`,
          header: false
        },
        {
          id: 4,
          label: 'B. Cancellation charges',
          value: `- ${currency} ${parseFloat(refundCharges)}`,
          header: true
        },
        {
          id: 5,
          label: 'Airline fare',
          value: `${currency} ${airlineCharges}`,
          header: false
        },
        {
          id: 6,
          label: 'Partner charges',
          value: `${currency} ${additionalInfo?.partnerCharges ?? 0}`,
          header: false
        },
        {
          id: 7,
          label: 'C. Non refundable',
          value: `- ${currency} ${nonRefundableFeeCharges}`,
          header: true
        },
        {
          id: 8,
          label: 'Convenience fee',
          value: `${currency} ${convenienceFeeCharges}`,
          header: false
        },
        {
          id: 9,
          label: 'Seat Charges',
          value: `${currency} ${seatCharges}`,
          header: false
        },
        {
          id: 10,
          label: 'Meal Charges',
          value: `${currency} ${mealCharges}`,
          header: false
        },
        {
          id: 11,
          label: 'Baggage Charges',
          value: `${currency} ${baggageCharges}`,
          header: false
        },
        {
          id: 12,
          label: 'Total refund amount',
          value: `${currency} ${totalRefundAmount}`
        },
        {
          id: 13,
          label: 'Refunded to Bank A/C',
          value: `${currency} ${refundAmountInCash}`
        }
      ];
};

export const getSMBChargesTable = (
  props: { data: any },
  SMBCount: { seatCount: number; mealCount: number; baggageCount: number }
) => {
  const orderAmount = props?.data?.orderAmount ?? 0;
  const currency = orderAmount?.currency;
  const seatCharges = parseFloat(orderAmount?.seatCharges ?? 0);
  const mealCharges = parseFloat(orderAmount?.mealCharges ?? 0);
  const baggageCharges = parseFloat(orderAmount?.baggageCharges ?? 0);
  const AddOns = seatCharges + mealCharges + baggageCharges;

  return [
    {
      id: 0,
      label: 'Add-Ons',
      value: `${currency} ${AddOns}`,
      header: true
    },
    SMBCount?.seatCount >= 1
      ? {
          id: 1,
          label: 'Seat Charges',
          value: `${currency} ${seatCharges}`,
          header: false
        }
      : null,
    SMBCount?.mealCount >= 1
      ? {
          id: 2,
          label: 'Meal Charges',
          value: `${currency} ${mealCharges}`,
          header: false
        }
      : null,
    SMBCount?.baggageCount >= 1
      ? {
          id: 3,
          label: 'Baggage Charges',
          value: `${currency} ${baggageCharges}`,
          header: false
        }
      : null
  ];
};

export const cancelTableData = (props: any) => {
  const orderAmount = props?.flightData?.orderAmount;
  const orgCode = props?.flightData?.userOrganization?.organizationCode;
  const currency = orderAmount?.currency;
  const totalAmount = orderAmount?.totalAmount;
  const amountInCash = orderAmount?.paymentSummary?.amountInCash;
  const amountInCoins = orderAmount?.paymentSummary?.amountInCoins;
  const airlineCharges = parseFloat(props?.refundInfo?.airLineCharges) ?? 0;
  const partnerCharges = parseFloat(props?.refundInfo?.partnerCharges) ?? 0;
  const convenienceFee = orderAmount?.paymentSummary?.convenienceFee;
  const totalRefundAmount = props?.refundInfo?.totalRefundAmount ?? 0;
  const seatCharges = orderAmount?.seatCharges ?? 0;
  const mealCharges = orderAmount?.mealCharges ?? 0;
  const baggageCharges = orderAmount?.baggageCharges ?? 0;
  const totalNonRefundableFeeCharges = props?.refundInfo?.totalNonRefundableFeeCharges ?? 0;

  return [
    {
      id: 1,
      label: 'A. Amount paid',
      value: `${currency} ${totalAmount}`,
      header: true
    },
    {
      id: 2,
      label: `Paid by customer (via ${
        props?.flightData?.orderAmount?.paymentSummary?.paymentMode
          ? props?.flightData?.orderAmount?.paymentSummary?.paymentMode
              ?.replace(/_/g, ' ')
              ?.toLowerCase()
          : 'UPI'
      })`,
      value: `${currency} ${amountInCash}`,
      header: false
    },
    {
      id: 3,
      label: `${
        !orgCode || orgCode?.toLowerCase()?.includes('niyo')
          ? 'Niyo global coins discount'
          : `${capitalizeFirstLetter(orgCode)} discount`
      }`,
      value: `- ${currency} ${amountInCoins}`,
      header: false,
      success: true
    },
    {
      id: 4,
      label: 'B. Cancellation charges',
      value: `- ${currency} ${airlineCharges + partnerCharges}`,
      header: true
    },
    {
      id: 5,
      label: 'Airline fare',
      value: `${currency} ${airlineCharges}`,
      header: false
    },
    {
      id: 6,
      label: 'Partner charges',
      value: `${currency} ${partnerCharges}`,
      header: false
    },
    {
      id: 7,
      label: 'C. Non refundable',
      value: `- ${currency} ${totalNonRefundableFeeCharges}`,
      header: true
    },
    {
      id: 8,
      label: 'Convenience fee',
      value: `${currency} ${convenienceFee}`,
      header: false
    },
    {
      id: 9,
      label: 'Seat Charges',
      value: `${currency} ${seatCharges}`,
      header: false
    },
    {
      id: 10,
      label: 'Meal Charges',
      value: `${currency} ${mealCharges}`,
      header: false
    },
    {
      id: 11,
      label: 'Baggage Charges',
      value: `${currency} ${baggageCharges}`,
      header: false
    },
    {
      id: 12,
      label: 'Total refund amount',
      value: `${currency} ${totalRefundAmount}`
    }
  ];
};
