import React, { useContext, useMemo, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { OATypography } from '../../../../components/widgets';
import OAButton from '../../../../components/widgets/OAButton';
import { ReactComponent as SourceDestIcon } from '../../../../assets/icons/source-DestArrow.svg';
import { ReactComponent as LayoverIcon } from '../../../../assets/icons/layOverIcon.svg';
import OAModalComponent from '../../../../components/OAModalComponent';
import OATableComponent from '../../../../components/OATableComponent';
import { getPNRColumns } from './PNRColumns';
import FlightContext from '../../context/FlightContext';
import { TripDetail } from './TripDetail';
import { theme } from '../../../../config/theme';
import { getFormattedDate } from '../../../../helper';
const TripType = ({
  onward,
  onwardDeparture,
  onwardArrival,
  checkIfSameDay,
  data,
  header
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pnr, setPNR] = useState<string | null>(null);
  const [ticketNumbers, setTicketNumbers] = useState<Record<string, string>>({}); //The `Record<K, T>` utility type constructs an object type where all properties of the object are of type `T`, and the keys are of type `K`. In this case, both key and values are strings.
  const { updatePNR } = useContext(FlightContext);

  const togglePNRModal = (buttonText?: string) => {
    setIsOpen(prev => !prev);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    let draft = onward?.ticketNumberInfo
      ?.filter((ticket: any) => {
        return ticket?.travellerId && (ticketNumbers[ticket?.travellerId] ?? ticket?.ticketNumber);
      })
      .map((ticket: any) => ({
        travellerId: ticket?.travellerId,
        ticketNumber: ticketNumbers[ticket?.travellerId] ?? ticket?.ticketNumber,
        pnrNumber: pnr ?? ticket?.pnrNumber
      }));
    let queryDraft = {
      id: data?.id,
      tripId: onward?.id
    };

    updatePNR(draft, queryDraft, (res: any) => {
      setIsLoading(false);
      togglePNRModal();
    });
  };

  const tableColumns = useMemo(() => {
    return getPNRColumns(ticketNumbers, setTicketNumbers);
  }, []);

  const tableData = useMemo(
    () =>
      data?.travellers?.map((traveller: any, i: number) => ({
        id: i,
        travellerName: `${traveller?.firstName} ${traveller?.lastName}`,
        pnr: onward?.ticketNumberInfo?.[i]?.pnrNumber ?? null,
        ticketNumber: onward?.ticketNumberInfo?.[i]?.ticketNumber ?? null,
        travellerId: traveller?.id
      })),
    [data]
  );

  let primaryTraveller = data?.travellers?.find(
    (obj: any) => obj?.userId === data?.contactDetails?.userId
  );
  const PNR =
    onwardDeparture?.bookingInfos?.find((obj: any) => obj?.travellerId === primaryTraveller?.id)
      ?.pnrNumber ?? onward?.pnrNumber;
  return (
    <div className='order-view--activity card pb-6'>
      <Box display='flex' justifyContent='space-between' alignItems='center' padding='16px'>
        <h3 className='card-title fw-bolder text-dark ps-4'>{header}</h3>
        {data?.bookingStatus !== 'ITINERARY' ? (
          <div>
            <OAButton
              label={`${PNR ? 'Edit' : 'Add'} PNR`}
              onClick={() => setIsOpen(true)}
              className='btn btn-light-primary ms-4'
            />
          </div>
        ) : (
          PNR && (
            <div>
              <OAButton
                label={'Edit PNR '}
                onClick={() => setIsOpen(true)}
                className='btn btn-light-primary ms-4'
              />
            </div>
          )
        )}
      </Box>
      <Divider />
      <TripDetail
        onward={onward}
        onwardDeparture={onwardDeparture}
        checkIfSameDay={checkIfSameDay}
        data={data}
      />

      {onward?.segments?.length > 1 && (
        <>
          {onward?.segments?.map((obj: any, index: number) => {
            if (index >= 1) {
              return (
                <Box>
                  <Box
                    marginLeft='16px'
                    marginRight='16px'
                    marginTop='24px'
                    gap='8px'
                    padding='4px 12px 4px 12px'
                    display='flex'
                    height='24px'
                    borderRadius='4px'
                    alignItems='center'
                    justifyContent='center'
                    sx={{ backgroundColor: theme.palette.primary.light }}>
                    <LayoverIcon />
                    <OATypography variant='body1' sx={{ fontSize: '12px', lineHeight: '16px' }}>
                      {`Layover ${
                        onward?.layovers[index - 1]?.typeDisplay
                          ? '•' + onward?.layovers[index - 1]?.typeDisplay
                          : ''
                      } • ${onward?.layovers[index - 1]?.duration?.display} at ${
                        onward?.layovers[index - 1]?.at
                      }`}
                    </OATypography>
                  </Box>
                  <TripDetail
                    onward={onward}
                    onwardDeparture={obj}
                    checkIfSameDay={checkIfSameDay}
                    data={data}
                  />
                </Box>
              );
            }
          })}
        </>
      )}
      <OAModalComponent
        size='lg' // NOTE: set modal size
        keyboard={false} // NOTE: disable keyboard event
        backdrop='static' // NOTE: disable backdrop click
        centered // NOTE: vertically center modal
        show={isOpen}
        onHide={togglePNRModal}
        header={{
          title: (
            <>
              <Box display='flex' alignItems='center' marginLeft='2px'>
                <OATypography variant='h4'>{`${
                  onward?.pnrNumber ? 'Edit' : 'Add'
                } PNR`}</OATypography>
              </Box>
            </>
          ),
          closeButton: true // NOTE: show close button on header
        }}
        body={{
          content: (
            <>
              <Box>
                <TripDetailInModal
                  onward={onward}
                  onwardArrival={onwardArrival}
                  onwardDeparture={onwardDeparture}
                  setPNR={setPNR}
                  data={data}
                />
              </Box>
              <OATableComponent columns={tableColumns} data={tableData} hideFooter />
              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                gap='16px'
                marginTop='16px'>
                <OAButton label='Cancel' color='secondary' onClick={() => setIsOpen(false)} />
                <OAButton label='Submit' onClick={submitHandler} isLoading={isLoading} />
              </Box>
            </>
          )
        }}
      />
    </div>
  );
};

const TripDetailInModal = ({ onward, onwardArrival, onwardDeparture, setPNR, data }: any) => {
  let primaryTraveller = data?.travellers?.find(
    (obj: any) => obj?.userId === data?.contactDetails?.userId
  );
  const pnr =
    onwardDeparture?.bookingInfos?.find((obj: any) => obj?.travellerId === primaryTraveller?.id)
      ?.pnrNumber ?? onward?.pnrNumber;
  return (
    <Box display='flex' gap='6px' alignItems='center' justifyContent='space-between'>
      <Box
        display='flex'
        gap='6px'
        alignItems='center'
        padding='16px'
        justifyContent='space-between'>
        <Box>
          <img
            width={32}
            height={32}
            src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/airline-logos/${onwardDeparture?.airline?.code}.svg`}
            alt='Indigo'
            loading='lazy'
            style={{ borderRadius: '50%' }}
          />
        </Box>
        <Box>
          <Box display='flex' gap='6px' alignItems='center'>
            <OATypography variant='h2'>{onwardDeparture?.departure?.airport?.city}</OATypography>
            <SourceDestIcon />
            <OATypography variant='h2'>{onwardArrival?.arrival?.airport?.city}</OATypography>
          </Box>
          <OATypography variant='body2' sx={{ color: theme.palette.grey[400] }}>
            {getFormattedDate(onwardDeparture?.departure?.date, 'ddd, DD MMM, YYYY')}
          </OATypography>
        </Box>
      </Box>
      <Box>
        <label htmlFor='pnr' className='form-label required'>
          PNR
        </label>
        <input
          type='text'
          className='form-control form-control-sm form-control-solid oa-text-search w-150px ps-9'
          placeholder='Enter PNR'
          defaultValue={pnr ?? ''}
          onChange={e => {
            setPNR(e?.target?.value)
          }
          }
        />
      </Box>
    </Box>
  );
};

export default TripType;
